import { useMemo } from "react";
import { useAppContext } from "../store";

export const useAppUrlPath = (path = "") => {

    const [{ config: { basePath } }] = useAppContext();

    return useMemo(() => {
        return basePath + path;
    }, [path, basePath]);

}